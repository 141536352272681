import React from 'react';
import s from '../../locale/success/s.json';

import {Page} from '../../components';

interface Props {
  t?: any;
}
interface State {}

type ComposedProps = Props;

export default class Success extends React.PureComponent<ComposedProps, State> {
  render() {
    const t = this.props.t || (() => []);
    return (
      <Page title="Success">
        <Page.Layout>
          <Page.Section>{t(s.successTitle)}</Page.Section>
        </Page.Layout>
      </Page>
    );
  }
}
